<template>
  <section
    class="relative w-screen h-screen flex flex-col justify-center items-center content-center bg-blueGray-600"
  >
    <div
      class="text-center md:pb-2 mt-28 text-blueGray-300 whitespace-nowrap text-sm uppercase font-bold p-4 px-2 flex justify-center items-baseline"
    >
      <img
        alt="vault logo"
        width="90"
        class="inline"
        src="@/assets/vault_transparent_white_logo.png"
      />
      <i class="fas fa-times px-1"></i>
      <span class="text-xl">{{ partner }}</span>
    </div>
    <div class="container mx-auto h-full" style="max-width: 80%">
      <div class="flex content-center items-center justify-center h-full">
        <div class="w-full lg:w-4/12">
          <div
            class="relative mx-auto flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0"
          >
            <div class="rounded-t mb-0 px-6 py-6">
              <div class="text-center mb-3">
                <h6 class="text-blueGray-500 font-bold text-xl">
                  {{ $t("Login") }}
                </h6>
              </div>
              <!-- <div class="btn-wrapper text-center">
              <button
                class="bg-white active:bg-blueGray-50 text-blueGray-700 font-normal px-4 py-2 rounded outline-none focus:outline-none mr-2 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-xs ease-linear transition-all duration-150"
                type="button"
              >
                <img alt="github" class="w-5 mr-1" src="" />
                {{ $t("Github") }}
              </button>
              <button
                class="bg-white active:bg-blueGray-50 text-blueGray-700 font-normal px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-xs ease-linear transition-all duration-150"
                type="button"
              >
                <img alt="google" class="w-5 mr-1" src="" />
                {{ $t("Google") }}
              </button>
            </div>
            <hr class="mt-6 border-b-1 border-blueGray-300" /> -->
            </div>
            <div class="flex-auto px-4 lg:px-10 py-10 pb-16 pt-0">
              <!-- <div class="text-blueGray-400 text-center mb-3 font-bold">
              <small>{{ $t("Or sign in with credentials") }}</small>
            </div> -->
              <form>
                <div class="relative w-full mb-3">
                  <label
                    class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    for="grid-password"
                  >
                    {{ $t("Email") }}
                  </label>
                  <input
                    v-model="email"
                    @keyup.enter="handleLogin"
                    type="email"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none w-full"
                    placeholder="Email"
                  />
                </div>
                <div class="relative w-full mb-3">
                  <label
                    class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    for="grid-password"
                  >
                    {{ $t("Password") }}
                  </label>
                  <div
                    class="flex justify-center items-center bg-white shadow rounded px-1"
                  >
                    <input
                      v-model="password"
                      @keyup.enter="handleLogin"
                      :type="showPassword ? 'text' : 'password'"
                      class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm focus:outline-none w-full"
                      placeholder="Password"
                    />
                    <i
                      class="fas text-blueGray-500 mr-2 cursor-pointer hover:text-blueGray-400"
                      :class="{
                        'fa-eye-slash': showPassword,
                        'fa-eye': !showPassword
                      }"
                      @click="showPassword = !showPassword"
                    />
                  </div>
                </div>
                <!-- <div>
                  <label class="inline-flex items-center cursor-pointer"
                    ><input
                      type="checkbox"
                      class="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                    /><span
                      class="ml-2 text-sm font-semibold text-blueGray-600"
                    >
                      {{ $t("Remember me") }}
                    </span>
                  </label>
                </div> -->
                <div class="text-center mt-6">
                  <button
                    @click="handleLogin"
                    class="text-white text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                    type="button"
                    :disabled="!(email && password)"
                    :class="{
                      'bg-blueGray-800 active:bg-blueGray-600':
                        email && password,
                      'cursor-not-allowed bg-blueGray-400': !(email && password)
                    }"
                  >
                    <i
                      class="fas fa-circle-notch fa-spin"
                      v-if="$store.state.accounts.loading"
                    />
                    {{ $t("Login") }}
                  </button>
                </div>
              </form>
              <GoogleLogin v-if="showGLogin" />
            </div>
          </div>
          <div class="flex flex-wrap mt-6 relative">
            <div class="w-1/">
              <router-link
                :to="{ name: 'PasswordReset' }"
                class="text-blueGray-200"
              >
                <small>{{ $t("Forgot password?") }}</small>
              </router-link>
            </div>
            <!-- <div class="w-1/2 text-right">
              <a href="#/auth/register" class="text-blueGray-200"
                ><small>{{ $t("Create new account") }}</small>
              </a>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import GoogleLogin from "../components/GoogleLogin.vue";
export default {
  name: "Login",
  components: { GoogleLogin },
  data() {
    return {
      showGLogin: false,
      showPassword: false,
      partner: process.env.VUE_APP_PARTNER
        ? process.env.VUE_APP_PARTNER
        : "You",
      email: "",
      password: ""
    };
  },
  methods: {
    handleLogin() {
      const { email, password } = this;
      if (email && password) {
        this.$store.dispatch("accounts/login", {
          user: { email, password }
        });
      }
    }
  },
  created() {
    // remove once google auth is through
    try {
      if (JSON.parse(localStorage.getItem("vault-withdropp"))) {
        this.showGLogin = true;
      }
    } catch (err) {
      //
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (vm.$store.state.accounts?.user?.token) {
        vm.$router.push({ name: "Home" });
      }
    });
  }
};
</script>
