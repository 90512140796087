<template>
  <div class="w-full mt-4 flex items-center justify-center" id="buttonDiv" />
</template>

<script>
export default {
  name: "GoogleLogin",
  mounted() {
    window.google.accounts.id.initialize({
      client_id: process.env.VUE_APP_G_CLIENT_ID,
      callback: response => {
        this.$store.dispatch("accounts/login", {
          user: { gcreds: response.credential },
          action: "LOGIN_WITH_GOOGLE"
        });
      }
    });
    window.google.accounts.id.renderButton(
      document.getElementById("buttonDiv"),
      { theme: "outline", size: "large" }
    );
    window.google.accounts.id.prompt();
  }
};
</script>
